import React from "react"
import Layout from "../components/layout-static"
import Seo from "../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "DUI and Criminal Defense Attorneys",
    description:
      "Facing a DUI or criminal charge? Contact us for expert defense in Connecticut. Our experienced attorneys can defend your case and minimize its impact on your life.",
    heroH1: "DUI and Criminal Defense Attorneys",
    heroH2: "Bartlett & Grippe will work to restore your life and reputation.",
    heroImage: "hero-gavel.jpg",
    heroImageAlt: "Photograph of a judge's gavel",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase">
              Every individual accused of a crime is entitled to a defense
            </h2>
            <p className="mb-8">
              Getting arrested for a DUI or other alcohol-related charges can be
              a scary and daunting event. The main witnesses against you are the
              police. In most cases, there is scientific evidence in the form of
              breath test and/or the blood tests presented against you. Any
              roadside video tape of your arrest, the officer’s testimony, and
              your performance on the Standard Field Sobriety tests may also
              present damaging evidence against you.
            </p>
            <p className="mb-8">
              In Connecticut, an arrest for DUI triggers can trigger an
              immediate 24 hour suspension, following by a 45 day suspension
              with the condition that you install and ignition interlock device
              into your car. This is in addition to the criminal charges filed
              by the state! In addition, you face possible jail time, fines,
              probation, and a stain on your driving history that will last for
              10 years.
            </p>
            <p className="mb-8">
              A DUI charge is a serious matter that deserves the attention of a
              serious attorney. Our firm’s DUI and criminal practice focuses on
              defending your case and minimizing the impact of a DUI charge on
              your life. Our firm utilizes the experience of a former state’s
              attorney and the very same materials used by Connecticut State
              Police in DUI Enforcement to defend your case.
            </p>
            <p className="mb-8">
              If you have been arrested for a DUI or any other criminal charge,
              contact our office so that we can help defend you.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
